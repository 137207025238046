import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Button, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "150px 0 150px 0",
	"background": "rgba(0, 0, 0, 0) url(https://uhanighets.com/img/4.jpg) 50% 50% /cover repeat scroll padding-box",
	"sm-padding": "80px 0 80px 0",
	"quarkly-title": "Product-7"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"justify-content": "flex-end"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"width": "400px",
			"background": "#ffffff",
			"padding": "50px 35px 50px 35px",
			"display": "flex",
			"align-items": "center",
			"flex-direction": "column",
			"md-padding": "35px 35px 35px 35px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"font": "normal 600 32px/1.2 --fontFamily-sans",
			"text-align": "center",
			"children": "Bize Ulaşın"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 35px 0px",
			"text-align": "center",
			"font": "normal 400 18px/1.5 --fontFamily-sansHelvetica",
			"children": <>
				Striker's Edge -{" "}
				<br />
				Futbol Hayallerinin Gerçeğe Dönüştüğü Yer
			</>
		}
	},
	"button": {
		"kind": "Button",
		"props": {
			"background": "--color-darkL1",
			"href": "/contact",
			"type": "link",
			"text-decoration-line": "initial",
			"padding": "12px 30px 12px 30px",
			"border-radius": "25px"
		}
	}
};

const Cta = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Text {...override("text")} />
				<Text {...override("text1")} />
				<Button {...override("button")}>
					Bizi Ziyaret Edin
				</Button>
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Cta, { ...Section,
	defaultProps,
	overrides
});
export default Cta;